import React, { useState } from 'react';
import Banner from '../components/Banner';
import Sustainability from '../components/Sustainability';
import PriceListBanner from '../components/PriceListBanner';
import FavoriteProducts from '../components/FavoriteProducts';
import VideoSection from '../components/VideoSection';
import PopUp from '../components/PopUp';
import SignUpButtom from '../components/SignUpButtom'
import CustomerReviews from '../components/CustomerReviews';

// CSS
import '../css/HomePage.css';
import Recommends from '../components/Recommends';

const HomePage = () => {

    // Banner data
    const bannerText1 = "Fisherman's Market";
    const bannerText2 = "Your Trusted leader in BC Seafood";
    const bannerText3 = "Expertly Sourced by Generations of Fishers";
    const bannerButtonText = 'SHOP NOW';
    const [showPopUp, setShowPopUp] = useState(false);

    // price list banner data

    const PriceListBannerText1 = "Inside Fisherman's Market";
    const PriceListBannerText2 = "From our west coast fishing vessels to our bustling fish markets";
    const PriceListBannerText3 = "A Glimpse into Our Daily Market Operations";
    const PriceListBannerButtonText = 'LEARN MORE';


    // FavoriteProducts data
    const favoriteProductsData = [
        { name: 'Salmon', imageUrl: '/images/salmon.jpg' },
        { name: 'Shrimp', imageUrl: '/images/shrimp.jpg' },
        { name: 'Cod', imageUrl: '/images/cod.jpg' },
        { name: 'Tuna', imageUrl: '/images/tuna.jpg' },
        { name: 'Scallops', imageUrl: '/images/scallops.jpg' },
    ];

    // VideoSection data
    const videoUrl = "https://www.youtube.com/embed/IMXDxk7LHio";

    return (
        <div className="HomePage">
            <Banner text1={bannerText1} text2={bannerText2} text3={bannerText3} buttonText={bannerButtonText} />
            <Sustainability />
            <PriceListBanner
                text1={PriceListBannerText1}
                text2={PriceListBannerText2}
                text3={PriceListBannerText3}
                buttonText={PriceListBannerButtonText}
            />
            <FavoriteProducts products={favoriteProductsData} />
            <CustomerReviews />
            <VideoSection videoUrl={videoUrl} />
            <PopUp
                show={showPopUp}
                onClose={() => setShowPopUp(false)}
                imageUrl='/images/popup.jpg'// use correct image path
            />
            <Recommends />
            <SignUpButtom />
        </div>
    );
};

export default HomePage;
