import React, { createContext, useState, useContext, useEffect } from 'react';
import { axiosUserInstance, axiosUserDetailsInstance } from "../dataFetch/axios.main"
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
// create context
const CartContext = createContext();
// customize hook for accessing shopping cart context in components
export const useCart = () => useContext(CartContext);
// provider component
export const CartProvider = ({ children }) => {
  const [shippingCost, setShippingCost] = useState();
  const [cartItems, setCartItems] = useState(() => {
    // Initialize state from local storage or use a default value
    const storedValue = localStorage.getItem('cart');
    return storedValue ? JSON.parse(storedValue) : [];
  });

  // Save cart data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
  }, [cartItems]);
  // add products to shopping cart
  const addItemToCart = (item) => {
    const existingItemIndex = cartItems.findIndex((cartItem) => cartItem.id === item.id);

    if (existingItemIndex !== -1) {
      // If the item is already in the cart, update the quantity
      setCartItems((prevItems) =>
        prevItems.map((cartItem, index) =>
          index === existingItemIndex
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    } else {
      // If the item is not in the cart, add a new entry
      setCartItems((prevItems) => [...prevItems, { ...item, quantity: 1 }]);
    }
  };

  const getItemsForCart = useMutation({
    mutationFn: (data) => {
      console.log("Fetching Data for Cart Items")
      const ids = data.join(',');
      // clean this for future
      return axiosUserDetailsInstance.get(`v3/products`, { params: { 'include': ids } });

    },
    onSuccess: async (response) => {
      console.log("Fetched Data for Cart Items");

      addItemsToCart(response.data);
      // localStorage.setItem('userDetails', JSON.stringify(response.data));
      // setUserDetails(response.data)
    },
    onError: async (error) => {
      console.log("Fail: Fetched Data for Cart Items");
      console.log(error)
    },
  });

  const addItemsToCart = (items) => {
    setCartItems((prevItems) => {
      // Create a copy of the previous items array
      let newItems = [...prevItems];

      // For each item to be added...
      items.forEach((item) => {
        const existingItemIndex = newItems.findIndex((cartItem) => cartItem.id === item.id);

        if (existingItemIndex !== -1) {
          // If the item is already in the cart, update the quantity
          newItems = newItems.map((cartItem, index) =>
            index === existingItemIndex
              ? { ...cartItem, quantity: cartItem.quantity + 1 }
              : cartItem
          );
        } else {
          // If the item is not in the cart, add a new entry
          newItems = [...newItems, { ...item, quantity: 1 }];
        }
      });

      // Return the updated items array
      return newItems;
    });
  };

  const getItemQuantity = (itemId) => {
    const item = cartItems.find(item => item.id === itemId);
    return item ? item.quantity : 1;
  };

  const isItemInCart = (itemId) => {
    return cartItems.some((item) => item.id === itemId);
  };

  const updateItemQuantity = (item, quantity) => {
    console.log(quantity);

    const itemIndex = cartItems.findIndex(cartItem => cartItem.id === item.id);
    // console.log(itemIndex)

    if (itemIndex !== -1) {
      // If the item is already in the cart, update the quantity
      setCartItems((prevItems) =>
        prevItems.map((cartItem, index) =>
          index === itemIndex
            ? { ...cartItem, quantity: quantity }
            : cartItem
        )
      );
    } else {
      // If the item is not in the cart, add a new entry
      setCartItems((prevItems) => [...prevItems, { ...item, quantity: quantity }]);
    }
  };

  // Function to remove an item from the cart
  const removeItemFromCart = (itemId) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId
          ? item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : null
          : item
      ).filter(Boolean)
    );
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cart');
  };

  const getLineItemsForWoo = (items) => {
    return items.map(item => ({
      product_id: item.id,
      quantity: item.quantity
    }));

  };

  // Function to remove an item from the cart
  const removeAllItemsOfType = (itemId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  // Function to calculate the total number of items in the cart
  const getTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const calculateTotalCartPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };
  const shippingCostCheckout = () => {
    return shippingCost ? shippingCost.toFixed(2) : 0;
  }
  const estimatedGST = parseFloat(shippingCostCheckout() * 0.05);
  // const estimatedPST = parseFloat(calculateTotalCartPrice() * 0.07);
  const estimatedPST = 0;
  const cartSubTotal = parseFloat(calculateTotalCartPrice()) + estimatedGST + estimatedPST + parseFloat(shippingCostCheckout());


  const calculateItemTotalPrice = (item) => {
    const itemTotalPrice = (item.price) * item.quantity;
    return itemTotalPrice.toFixed(2);
  };

  // Shipping Discount Calculation


  const contextValue = {
    cartItems,
    addItemToCart,
    addItemsToCart,
    removeItemFromCart,
    removeAllItemsOfType,
    getTotalItems,
    calculateTotalCartPrice,
    calculateItemTotalPrice,
    getLineItemsForWoo,
    updateItemQuantity,
    getItemQuantity,
    isItemInCart,
    estimatedGST,
    estimatedPST,
    cartSubTotal,
    clearCart,
    getItemsForCart,
    shippingCost, setShippingCost, shippingCostCheckout
  };

  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  );
};