import React, { useState, useEffect, useRef } from 'react';
import '../css/Navbar.css';
import logoImage from '../images/FMS_horizontal-logo-RGB-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCartShopping, faUser, faXmark, faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { useUser } from '../context/UserContext';
import SearchResult from './SearchResult';

import NavBarLink from './NavBarLink';

const Navbar = () => {
  const { user, isLoggedIn, signOut } = useUser();
  const { getTotalItems } = useCart();
  // State to track if the search input should be shown
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  // Function to toggle the search input visibility
  const searchBarRef = useRef(null);

  const toggleOn = () => {
    setShowSearch(true);
  }
  const toggleOff = () => {
    setShowSearch(false);
  }

  // State to hold whether the page has been scrolled down
  const [scrolled, setScrolled] = useState(false);
  // Effect hook to add/remove scroll event listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        toggleOff();
      }
    };
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  return (
    <div className={scrolled ? 'navbar scrolled' : 'navbar'}>
      <div className="navbar-top-wrapper">
        <div className="navbar-top">
          <Link to="/" className="navbar-logo">
            <img src={logoImage} alt="Logo" className="logo" />
          </Link>
          <div className="navbar-bottom">
            {showMenu && (
              <div className="toggle-menu">
                <ul className={scrolled ? 'navbar-bottom-small scrolled' : 'navbar-bottom-small'}>
                  <li>
                    <a onClick={() => setShowMenu(false)}>
                      <FontAwesomeIcon icon={faXmark} />
                    </a>
                  </li>
                  <li><Link to="/" onClick={() => setShowMenu(false)}>HOME</Link></li>
                  <li><Link to="/shop" onClick={() => setShowMenu(false)}>SHOP ONLINE</Link></li>
                  <li><Link to="/our-market" onClick={() => setShowMenu(false)}>OUR MARKET</Link></li>
                  <li><Link to="/locations" onClick={() => setShowMenu(false)}>LOCATIONS</Link></li>
                  <li><Link to="/recipes" onClick={() => setShowMenu(false)}>RECIPES</Link></li>
                  <li><Link to="/blog" onClick={() => setShowMenu(false)}>RESOURCES</Link></li>
                  <li><Link to="/fundraiser" onClick={() => setShowMenu(false)}>FUNDRAISERS</Link></li>
                  <li><Link to="/about-us" onClick={() => setShowMenu(false)}>ABOUT US</Link></li>
                  <li><Link to="/shipping" onClick={() => setShowMenu(false)}>SHIPPING</Link></li>
                </ul>
                <div className="navbar-overlay" onClick={() => setShowMenu(false)}></div>
              </div>
            )}

            <ul className="navbar-bottom-normal">
              <li><Link to="/shop" style={{ fontWeight: 'bold', color: '#e9a343' }}>SHOP ONLINE</Link></li>
              <li><Link to="/our-market">OUR MARKET</Link></li>
              <li><Link to="/locations">LOCATIONS</Link></li>
              <li><Link to="/recipes">RECIPES</Link></li>
              <li><Link to="/blog">RESOURCES</Link></li>
              <li><Link to="/fundraiser">FUNDRAISERS</Link></li>
              <li><Link to="/about-us">ABOUT US</Link></li>
              <li><Link to="/shipping">SHIPPING</Link></li>
            </ul>
          </div>

          <div className="navbar-buttons">
            <button onClick={() => setShowMenu(true)} className="menu-button">
              <FontAwesomeIcon icon={faBars} />
            </button>
            <button onClick={toggleOn} className="search-button">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>

            <Link to="/cart" className="cart-button">
              <FontAwesomeIcon icon={faCartShopping} />
              {getTotalItems() > 0 && <span className="cart-count">{getTotalItems()}</span>}
            </Link>
            {isLoggedIn ? (
              <>
                <Link to="/customer/account" className="signin-button">
                  Account
                </Link>
                <Link className="signout-button" onClick={signOut}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </Link>

              </>

            ) : (
              <Link to="/customer/sign-in" className="signin-button">
                <FontAwesomeIcon icon={faUser} />
              </Link>
            )}
          </div>
        </div>
      </div>
      {showSearch && (
        <div ref={searchBarRef} className="search-bar">
          <div className="search-bar-wrapper">
            <SearchResult />
            <button onClick={toggleOff}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        </div>
      )}
    </div >

  );
};

export default Navbar;
