import React from 'react';
import './css/Stores.css';
import storeData from './data/storeData'
import bannerImage from './images/opt-FishermansMarket_Nelson_store_Crew1.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SignUpButton from './components/SignUpButtom';

const Stores = () => {

    return (
        <div className="stores-list">
            {/* <div className="store-list-banner" style={{ backgroundImage: `url(${bannerImage})` }}></div> */}
            <div className="store-list-content">
                <div className="list-content-preface">
                    <h2 className="page-title">Store Locations</h2>
                    <p className="page-des">We meticulously create a coastal atmosphere at each Fisherman's Market, making it feel like you're meeting the boat at the dock. Our expert staff, many of whom are fishers, help you choose the freshest catch for your meal.</p>
                    <p className="page-des">Each of our British Columbia stores features expert fishmongers who offer personalized help in selecting sustainable, high-quality, fresh seafood at convenient locations.</p>
                    <p className="page-des">Your Trusted leader in BC Seafood</p>
                </div>

                <div className="store-list">
                    {storeData.map((store, index) => {
                        return (
                            <div className="store-list-item" key={index} >
                                <div>
                                    <div className="store-photo" style={{ backgroundImage: `url(${store.imageUrl})` }}>
                                    </div>
                                    <a href={store.mapLink} target="_blank" rel="noreferrer" title="Google Map" className='store-navi'>
                                        Go There
                                    </a>
                                </div>
                                <div>
                                    <h3>{store.name}</h3>

                                    {store.hours.map((hour, indexY) => {
                                        return (
                                            <div className="store-open" key={indexY}>
                                                <span>{hour.day} </span>
                                                <span>{hour.time}</span>
                                            </div>
                                        );
                                    })}
                                    <p>{store.closed}</p>

                                    <p>
                                        <a href={store.mapLink} target="_blank" rel="noreferrer" title="Google Map">
                                            {store.address}<br /> {store.city}, {store.province} {store.zip}
                                        </a>
                                    </p>
                                    {store.note && <p>{store.note}</p>}
                                    <p>{store.phone}</p>
                                    <p><a href={`mailto:${store.email}`}>{store.email}</a></p>
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>
            <SignUpButton />
        </div>
    );
};

export default Stores;