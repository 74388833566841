import React from 'react';
import '../css/PopUp.css'; 
import popupImage from '../images/popup.jpg';

const PopUp = ({ show, onClose, imageUrl }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup">
      <div className="popup-inner">
      <div className="popup-left" style={{ backgroundImage: `url(${popupImage})` }}></div>
        <div className="popup-right">
          <button className="close-button" onClick={onClose}>×</button>
          <div className="popup-content">
            <h2>Sign up for emails and take <span style={{ color: 'red' }}>15% OFF</span> your first catch!</h2>
            <input type="text" placeholder="Name" />
            <input type="email" placeholder="Email" />
            <input type="text" placeholder="Phone Number" />
            <button className="popup-signup-button">SIGN UP NOW</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
