import React from 'react';
import blogData from './data/blogData';
import { Link } from 'react-router-dom';
import './css/BlogList.css';
import Recommends from './components/Recommends';
import SignUpButton from './components/SignUpButtom';

const BlogList = () => {

    return (
        <div className="blog-list">
            <div className="blog-list-title">
                <h2>Resources</h2>
                <p>Check out our articles for information on the ocean, commercial fishing, fish mongering and much more.</p>
            </div>

            <div className="blog-list-filter">
                <div className="filter">
                    <select value={""} onChange={""}>
                        <option value="">Category</option>
                    </select>
                </div>
                <button className="filter-button">RESET</button>
            </div>

            <div className="blog-list-container">
                {blogData.map((blog, index) => (
                    <Link to={`/blog/${blog.id}`} key={index} className="blog-container-list">
                        <div className="blog-image-wrapper">
                            <div className="blog-image" style={{ backgroundImage: 'url(' + blog.imgUrl + ')' }} ></div>
                        </div>
                        <div className="blog-info">
                            <div className="blog-info-title">{blog.title}</div>
                        </div>
                        <a href="#">Read Now
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                        </a>
                    </Link>
                ))}
            </div>
            <Recommends />
            <SignUpButton />
        </div>

    )
}

export default BlogList;
