const recipeData = [
    {
        id: 1,
        title: 'Mediterranean halibut cheeks',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'February 6, 2023',
        type: ['Halibut'],
        prepTime: '10 min + 1hr marinate',
        prepTimeValue: 70,
        cookTime: '40 min',
        cookTimeValue: 40,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '1lb of halibut cheeks from your local Fisherman’s Market, thawed',
                    '1 container of grape tomatoes, halved',
                    '1 can medium pitted black olives, drained',
                    '1 medium red onion, diced',
                    '½ cup tricolour quinoa, cooked',
                    '2 tbsp feta cheese, crumbled'
                ]
            },
            {
                id: 2,
                subTitle: 'Marinade',
                list: [
                    '¼ cup olive oil',
                    '2tbsp fresh parsley',
                    '1tsp minced garlic (or ½ tsp garlic powder)',
                    '2 tsp  lemon pepper',
                    'salt to preference'
                ]
            },
            {
                id: 3,
                subTitle: 'Quinoa dressing',
                list: [
                    '1 tbsp olive oil',
                    '1 tsp dried oregano',
                    '1 tsp minced garlic',
                    '1 tsp lemon pepper',
                    '1 tbsp feta cheese'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Combine your marinade and coat halibut cheeks, saving some for your veggies, and let marinate in the fridge for at least an hour.',
                    'Toss tomatoes, olives and red onion in the rest of the marinade and roast in a 400°F oven for 30  minutes.',
                    'While veggies are roasting, cook quinoa as per package instructions. Make up your dressing in a  small bowl and add warmed quinoa once done.',
                    'On the stove get a dry nonstick pan warmed to medium-high. Without crowding the pan, place your halibut cheeks into the pan and cook for 3 minutes, flip, cook for 2 minutes and then remove from heat.',
                    'To plate: Add quinoa and veggies to the center of the plate, and top with feta cheese. Top with your halibut cheeks, and then garnish with a drizzle of olive oil and a sprinkle of parsley.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Jessi, Kelowna store manager, 2023.',
            chef: 'Chef Jessi'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_mediterranean_halibut_cheeks_recipe-scaled.webp'
    },
    {
        id: 2,
        title: 'Brown butter pickerel',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'January 30, 2023',
        type: ['Pickerel'],
        prepTime: '3 min',
        prepTimeValue: 3,
        cookTime: '6 min',
        cookTimeValue: 6,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '500g pickerel from your local Fisherman’s Market',
                    '6 tbsp butter',
                    '3 tbsp oil',
                    'Salt & pepper to taste'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Season pickerel with salt and pepper to taste',
                    'Start saucepan with 3 tbsp oil and 2 tbsp butter',
                    'Bring to medium/high heat',
                    'Add pickerel once the butter starts to bubble',
                    'Cook for 2-3 minutes, then flip',
                    'Add 4 tbs butter',
                    'Continue cooking for an additional 2-3 minutes while basting or spooning the butter over the pickerel (as it cooks, the butter will turn brown in colour due to the solids cooking and darkening, which will leave you with a wonderful nutty aroma)',
                    'Add this to any pasta or salad!'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Ty Gordon, Fisherman’s Market Burnaby store manager, 2023.',
            chef: 'Chef Mike'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_ChefTy_brown_butter_pickerel_recipe-scaled.webp'
    },
    {
        id: 3,
        title: 'Smoked salmon scallops',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'January 9, 2023',
        type: ['Salmon', 'Scallops'],
        prepTime: '20 min',
        prepTimeValue: 20,
        cookTime: '5 min',
        cookTimeValue: 5,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '12 x 10/20 scallops from your local Fisherman’s Market',
                    '12 slices smoked salmon from your local Fisherman’s Market'
                ]
            },
            {
                id: 2,
                subTitle: 'Crème Fraîche',
                list: [
                    '½ cup crème fraîche',
                    '1 tsp lemon zest',
                    '1.5 tsp finely diced chives',
                    '1 tbsp Ethical Table Canadian Seas (substitute Canadian Seas for fresh dill)',
                    '1 tsp lemon juice',
                    'Salt & pepper to taste'
                ]
            },
            {
                id: 3,
                subTitle: 'Garnish (optional)',
                list: [
                    'Lemon zest',
                    'Edible flowers',
                    'Fresh dill',
                    'Thinly sliced watermelon radish'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Make crème fraîche at least two hours ahead of time and let chill in the fridge.',
                    'Pat scallops dry with a paper towel.',
                    'Roll out smoked salmon, slice lengthwise to fit the height of the scallop and wrap the scallop gently.',
                    'Sear the scallops for approximately two minutes until you have a golden brown sear. Flip the scallops and place the pan in the oven for two mins to cook the scallops medium rare. Rest scallops for two mins out of the pan before plating.',
                    'Plate by adding crème fraîche and the scallops, and finish with the garnish.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Mike from the Ethical Table.',
            chef: 'Chef Mike'
        },
        videoUrl: 'https://www.instagram.com/reel/CnNEZIiO1CQ/?igshid=YmMyMTA2M2Y=',
        imgUrl: '/images/recipes/FishermansMarket_ChefMike_smoked_salmon_scallops_recipe-scaled.webp'
    },
    {
        id: 4,
        title: 'Jambalaya soup',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'December 28, 2022',
        type: ['Prawns'],
        prepTime: '15 min',
        prepTimeValue: 15,
        cookTime: '30 min',
        cookTimeValue: 30,
        serves: '8',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '1lb peeled, deveined, tail-off prawns (41/50 size  or 31/40 size work best) from your local Fisherman’s Market',
                    '1lb andouille sausage, sliced into rounds',
                    '3 strips thick sliced bacon, diced',
                    '1 tbsp butter or olive oil',
                    '1 red onion, large diced',
                    '1 white onion, large diced',
                    '2 bell pepper, large diced',
                    '4 stalks of celery, thin sliced',
                    '6 garlic cloves, minced',
                    '1 jalapeno, thin sliced',
                    '1x 15oz can diced tomatoes in water',
                    '1x 15oz can black beans',
                    '1 cup whole-kernel corn',
                    '1L chicken stock + 1L water',
                    '1 tbsp Old Bay seasoning + 1 tsp separated',
                    '1 tbsp Cajun seasoning',
                    '2 bay leaves',
                    'Salt & pepper'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Prep your veggies: onions, peppers, celery and jalapeno can be added to a bowl together. Minced garlic separate. Rinse and drain beans, and have your diced tomato can open (you want the liquid added to the soup so do not drain) and corn measured.',
                    'In a large saucepan or stock pot, heat to medium and add in diced bacon and sausage. Cook until the bacon is crispy and the sausage rounds are browning, remove from the pot with a slotted spoon, leaving the bacon fat behind and add in butter. Add garlic to the pot and sauté for one minute, before adding the bowl of veggies and sautéing until onions become semi-transparent. Add in your 1 tbsp Old Bay and Cajun seasoning and stir well to bloom flavours.',
                    'Add in the can of diced tomatoes, can of black beans, cup of corn, chicken stock, water and bay leaves. Allow to come to a simmer and cook for 15 minutes until veggies are soft.',
                    'In a separate frying pan heated to medium-high, add prawns with 1 tsp Old Bay and a little olive oil. Cook until prawns are opaque and firm, and then spoon them into the soup, adding back in the sausage and bacon at the same time.',
                    'Keeps well in the fridge for up to 4 days.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Jessi, Kelowna store manager, 2022.',
            chef: 'Chef Jessi'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_ChefJessi_Jambalaya_soup_recipe-scaled.webp'
    },
    {
        id: 5,
        title: 'Spicy ahi tuna on crispy rice',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'December 28, 2022',
        type: ['Tuna'],
        prepTime: '15 min',
        prepTimeValue: 15,
        cookTime: '10 min',
        cookTimeValue: 10,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '2 x 4oz ahi tuna steaks from your local Fisherman’s Market, semi-thawed (it is easier to slice if sort of frozen, though fully thawed is fine with a sharp knife)',
                    '1 cup of cooked sushi rice',
                    '1 tsp mirin',
                    '1 tsp rice vinegar',
                    '1 tsp black sesame seeds',
                    'Oil for pan frying'
                ]
            },
            {
                id: 2,
                subTitle: 'For Honey-Sriracha drizzle',
                list: [
                    '2 tbsp honey',
                    '1 tsp light soy sauce',
                    '1 tsp sriracha',
                    'Pinch of chilli flakes',
                    'Pinch of black sesame seeds',
                    '1 jalapeno, thinly sliced and rinsed to remove seeds'
                ]
            },
            {
                id: 3,
                subTitle: 'Special equipment',
                list: [
                    'Rice cooker or stove',
                    '6 x 9 loaf pan/8×8 cake pan or similar to form rice into a rectangle or square.'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Preparing the rice: add the rice to a bowl with cold water and agitate with your hands to rinse off as much starch as possible, strain in a sieve and repeat 2-3 times until the water runs clear.',
                    'Rice cooker: cook according to instructions. On a stove: add 1 cup rinsed rice and 1 cup fresh water to a small pot on the stove at medium heat until water begins to gently boil, then lid tightly and reduce heat to low and cook for 15 minutes.',
                    'Once the rice is cooked, add in mirin, vinegar and sesame seeds and fluff rice with a fork gently so as not to mash the grains. Transfer to a loaf pan lined with cling film/parchment paper (the rice will be sticky so running cold water on your utensils will help with sticking). Press into an even layer gently (about 2cm thick), you want to form the rectangular shape without crushing the rice as much as possible. Once pressed into a flat rectangle, place it in the fridge to firm up.',
                    'Prepare the sauce: mix all ingredients together in a small bowl and set aside.',
                    'Prepare the tuna: with a very sharp knife, cut your tuna into thin slices, and place it in the fridge while you fry your rice.',
                    'Remove the rice from the fridge and lift it from the pan in the cling film. With a wet knife, cut into rectangles that match the size of your tuna slices.',
                    'Heat oil in a small frying pan until a grain of rice dropped in the oil starts to bubble quickly. Fry your rice rectangles a few at a time, flipping when golden.',
                    'To assemble: place your crispy rice on a plate, and top it with slices of tuna and a spoonful of sauce. Finish the plate with a small drizzle of sauce all around and serve.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Jessi, Kelowna store manager, 2022.',
            chef: 'Chef Jessi'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_ChefJessi_ahi_tuna_on_crispy_rice_recipe-scaled.webp'
    },
    {
        id: 6,
        title: 'Halibut & burrata sandwich',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'December 21, 2022',
        type: ['Halibut'],
        prepTime: '10 min',
        prepTimeValue: 10,
        cookTime: '8 min',
        cookTimeValue: 8,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '1lb halibut cubes, no skin, from your local Fisherman’s Market',
                    '1 burrata',
                    '6 strips of prosciutto',
                    'Arugula (as much as you’d like)',
                    '2 Portuguese buns (or equivalent)',
                    'Balsamic reduction/glaze (for drizzling)',
                    'Olive oil (for drizzling )',
                    '2 Rosemary twigs (chopped)',
                    '5 tbsp Jerry’s Faves No Bull',
                    'Salt & pepper to taste',
                    '1/4 cup cornstarch',
                    '1/4 cup flour',
                    '1/2 cup oil for frying',
                    '3 tbsp mayo (to spread on buns)',
                    '2 tbsp of butter (to toast buns)'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Pat the dry halibut, and cut it into 1.5-1 inch cubes.',
                    'Season the halibut with salt, pepper, rosemary and Jerry’s Faves No Bull.',
                    'Combine the starch, flour and rosemary in a mixing bowl.',
                    'Toss the halibut in the starch and flour mixture until evenly coated.',
                    'Preheat the skillet with oil to medium heat and fry the halibut, turning every 1.5 minutes. Cook for 3-5 minutes.',
                    'Place the halibut on the toasted buns with mayo and the rest of the ingredients to your preference.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Ty Gordon, Fisherman’s Market Burnaby store manager, 2022.',
            chef: 'Chef Mike'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_halibut_burrata_sandwich_recipe-scaled.webp'
    },
    {
        id: 7,
        title: 'Sesame-crusted tuna tataki',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'October 28, 2022',
        type: ['Tuna'],
        prepTime: '5 min',
        prepTimeValue: 5,
        cookTime: '2 min',
        cookTimeValue: 2,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '2 x 6oz ahi tuna steaks from your local Fisherman’s Market',
                    '½ cup of sesame seeds',
                    '3 tbsp oil for frying (peanut, canola, vegetable)',
                    'Salt & pepper to taste'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Pat the ahi tuna steaks dry and then lightly season them with salt and pepper on all sides.',
                    'Place the sesame seeds on a flat plate, take the ahi tuna steaks and coat all sides in the sesame seeds.',
                    'In a saucepan, heat the oil on medium-high heat until the oil slightly smokes.',
                    'Place coated ahi tuna steaks in a pan and let sear for 30 seconds (or until golden) on each side.',
                    'Let sit for 2 minutes then slice thinly and serve.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Tyrone Gordon, Fisherman’s Market Burnaby store manager.',
            chef: 'Tyrone Gordon'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_sesame_crusted_tuna_tataki_recipe-scaled.webp'
    },
    {
        id: 8,
        title: 'Scallop and Pappardelle pasta with mushrooms and goat cheese',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'October 28, 2022',
        type: ['Scallops'],
        prepTime: '15 min',
        prepTimeValue: 15,
        cookTime: '30 min',
        cookTimeValue: 30,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '8 10/20 Hokkaido scallops from your local Fisherman’s Market (other sizes and kinds will work as well)',
                    '3 nests of Pappardelle',
                    '1 egg yolk',
                    '100g semi-soft goat cheese (can add more or less based on your preference)',
                    '2 portobello mushrooms',
                    '2 tbsp oil (vegetable or other oil)',
                    '4 tbsp butter (1 tbsp separated)',
                    '2 cloves of garlic (minced)',
                    '2 tbsp salt',
                    'Salt & pepper to taste'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'In a medium-sized pot, bring 6 cups of water and 2 tablespoons salt to a boil, then add pasta and simmer until pasta is al dente (about 10-12 minutes). Set aside 1/4 cup of pasta water and strain the rest.',
                    'While pasta is cooking, heat a saucepan to medium heat, add 1 tbsp oil then mushrooms and garlic and cook until mushrooms are dark and cooked through. Then add goat cheese and 3 tbsp of butter, reduce heat and mix until combined.',
                    'Add the cooked pasta to the mushrooms and goat cheese and stir in 1/4 cup of pasta water and let simmer. Stir until everything is combined.',
                    'Pat the scallops dry and generously sprinkle salt on top.',
                    'Heat a small pan on high with 1 tbsp of oil until it starts to smoke, add scallops salt side down in a circle formation, then sprinkle salt on the top (place scallops between 1 1/2cm to 2cm apart from each other).',
                    'Going around the circle, flip each scallop after 1 minute or when golden brown on the bottom. Cook for 30 seconds then add 1 tbsp of butter, baste over the scallops for an additional 30 seconds then set aside to rest for 30 seconds.',
                    'Plate the pasta and add the scallops on top.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Tyrone Gordon, Fisherman’s Market Burnaby store manager.',
            chef: 'Tyrone Gordon'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_scallop_and_pappardelle_pasta_with_mushrooms_and_goat_cheese_recipe-scaled.webp'
    }
];

export default recipeData;